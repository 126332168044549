<template>
  <el-dialog
    title="选择专业"
    :visible.sync="show"
    :before-close="close"
    class="choiceMajor"
    width="70%"
  >
    <div class="top">
      <el-input
        placeholder="请输入要检索的专业(多个时用空格间隔),按enter键进行搜索"
        class="item"
        v-model="schoolsubject.subjectName"
        @change="getList"
      ></el-input>
      <span class="item majorText"
        >已选择专业<span class="majorNum">{{ this.outsubjectlist.length }}</span
        >个</span
      >
      <el-link
        :underline="false"
        type="primary"
        class="item"
        @click="schoolClear"
        >清空所选</el-link
      >
      <el-button type="primary" size="small" class="item" @click="choiceCheck"
        >确认</el-button
      >
    </div>
    <el-table
      :data="list"
      border
      ref="mainTable"
      :row-style="{ height: '40px' }"
      style="min-height: 400px"
      @selection-change="choice"
      @row-click="rowClick"
      :header-row-style="{ cursor: 'pointer' }"
    >
      <el-table-column
        type="selection"
        align="center"
        width="55"
      ></el-table-column>
      <el-table-column
        prop="schoolcode"
        label="学校编号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="subjectName"
        label="专业名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="subjectCode"
        label="专业代码"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="plans"
        label="计划数"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="posts"
        label="投档数"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="receives"
        label="录取数"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="scores"
        label="分数详情"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="minscore"
        label="最低分"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="avescore"
        label="平均分"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="scoreline"
        label="分数线"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="minscoredif"
        label="最低分线差"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="avescoredif"
        label="平均分线差"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="minplace"
        label="最低分名次"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="scorelineplace"
        label="分数线名次"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="aveplace"
        label="平均分名次"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="plansadd"
        label="计划数增加"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <Pagination
      v-show="total > 0"
      :total="total"
      :page.sync="schoolsubject.page"
      :limit.sync="schoolsubject.limit"
      @pagination="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
import * as categorys from "@/api/categorys";
import * as schoolsubjects from "@/api/schoolsubjects";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      show: false,
      gategoryList: [],
      subjectChangeList: [],
      schoolsubject: {
        page: 1,
        limit: 10,
        sySYear: "2021",
        SYS_Times: "",
        SYS_SubjectGroup: "",
        SchoolCode: "",
        subjectName: "",
      },
      list: null,
      outsubjectlist: [],
      subjectChooseNum: 0,
      total: 0,
    };
  },
  props: {
    choiceShow: Boolean,
    choiceSchool: Object,
  },
  methods: {
    handleCurrentChange(val) {
      this.schoolsubject.page = val.page;
      this.schoolsubject.limit = val.limit;
      this.getList();
    },
    close() {
      this.$emit("closeChoic", false);
    },
    schoolClear() {
      if (window.confirm("确认清除所有选项？")) {
        //console.log(this.list);
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].select == true) {
            this.$refs.mainTable.toggleRowSelection(this.list[i], false); //设置复选框状态
            this.list[i].select = false;
          }
        }
        this.subjectChooseNum = 0;
        this.$emit("clearSubject", this.schoolsubject.SchoolCode);
        this.close();
      }
    },
    sumit(form) {
      console.log(form);
    },
    rowClick(row) {
      //console.log(row.select);

      let index = this.list.findIndex((item) => {
        return item == row;
      });
      //console.log(index);
      if (row.select == undefined || row.select == false) {
        this.outsubjectlist.push(row);
        this.subjectChooseNum += 1;
        //console.log(this.outsubjectlist);
        //console.log("success");
      } else if (row.select == true) {
        this.outsubjectlist.splice(index, 1);
        this.subjectChooseNum -= 1;
        //console.log(this.outsubjectlist);
        //console.log("error");
      }

      this.$refs.mainTable.toggleRowSelection(row, !this.list[index].select); //设置复选框状态
      this.list[index].select = !this.list[index].select;
    },
    choice(val) {
      this.outsubjectlist = val;
      //this.$emit("recList", val);
    },
    choiceCheck() {
      //console.log(val);
      var outlist = [];
      //var alloutlist = [];
      if (this.outsubjectlist.length == 0) {
        this.$message.error("专业选择为空,请先选择专业");
        return;
      }
      for (let i = 0; i < this.outsubjectlist.length; i++) {
        var volunteerSubject = {};
        console.log(this.subjectChangeList);
        let index = 0;
        if (this.subjectChangeList == undefined) {
          index = -1;
        } else {
          index = this.subjectChangeList.findIndex((item) => {
            return item.subjectCode == this.outsubjectlist[i].subjectCode;
          });
        }
        if (index !== -1 && this.subjectChangeList != undefined) {
          volunteerSubject = {
            id: this.subjectChangeList[index].id,
            schoolCode: this.schoolsubject.SchoolCode,
            subjectCode: this.outsubjectlist[i].subjectCode,
            subjectName: this.outsubjectlist[i].subjectName,
          };
        } else {
          volunteerSubject = {
            id: "",
            schoolCode: this.schoolsubject.SchoolCode,
            subjectCode: this.outsubjectlist[i].subjectCode,
            subjectName: this.outsubjectlist[i].subjectName,
          };
        }

        outlist.push(volunteerSubject);
      }
      // var volunteerSchool = {
      // 	schoolCode: this.schoolsubject.SchoolCode,
      // 	SYS_Times: this.schoolsubject.SYS_Times,
      // 	SYS_SubjectGroup: this.schoolsubject.SYS_SubjectGroup,
      // 	volunteerSubject: outlist,
      // };
      //console.log(this.outsubjectlist);
      //alloutlist=outlist;
      //console.log(alloutlist);
      //this.outsubjectlist = [];
      this.subjectChangeList = [];
      if (outlist.length !== 0) {
        this.$emit("recList", outlist);
      }
      this.close();
    },
    getList() {
      this.list = [];
      this.listLoading = true;
      //console.log(this.schoolsubject);
      schoolsubjects.getList(this.schoolsubject).then((response) => {
        let list1 = response.data;
        console.log(list1);
        for (let i = 0; i < list1.length; i++) {
          var o = list1[i];
          this.list.push(o);
          if (this.list[i].select == true) {
            this.subjectChooseNum += 1;
          }
        }
        this.total = response.count;
        this.listLoading = false;
        //console.log(this.list);
        this.selectClickRow(this.subjectChangeList);
      });
    },
    getGategorys() {
      let listQuery = {
        page: 1,
        limit: 9999,
      };
      //console.log('getcategorys')
      categorys.getList(listQuery).then((res) => {
        this.gategoryList = res.data;
        //console.log(this.gategoryList);
        //for(let key in this.gategoryList){
        //    console.log(this.gategoryList[key])
        //}
        //console.log('getcategorys3')
      });
    },
    getGategoryName(val, columnName) {
      var dicobject = this.gategoryList.find(
        (t) =>
          t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
      );
      //var dicobject = this.gategoryList.find(t => {
      //  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
      //    return t
      //  }
      //});

      if (!dicobject) {
        return "";
      } else {
        return dicobject.name;
      }
    },
    selectClickRow(val) {
      console.log(val);
      if (val != undefined) {
        for (let i = 0; i < val.length; i++) {
          let index = this.list.findIndex((item) => {
            return item.subjectCode == val[i].subjectCode;
          });
          console.log("this index=" + index);
          this.$refs.mainTable.toggleRowSelection(this.list[index], true); //设置复选框状态
          this.list[index].select = true;
        }
      }
    },
  },
  watch: {
    choiceShow(nv) {
      //this.getList();
      this.show = nv;
    },
    choiceSchool(nv) {
      //this.show = true;
      //console.log(nv.tempTaglist);
      //console.log(nv);
      this.schoolsubject.SchoolCode = nv.schoolCode;
      this.schoolsubject.SYS_Times = nv.syS_Times;
      this.schoolsubject.SYS_SubjectGroup = nv.syS_SubjectGroup;
      this.subjectChangeList = nv.tempTaglist;
      //console.log(this.subjectChangeList);
      this.getList();

      //this.subjectChooseNum = 0;
    },
  },
};
</script>

<style lang="scss">
.choiceMajor {
  .top {
    display: flex;
    text-align: right;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;

    .item {
      padding: 0 15px;
    }

    .el-input {
      width: 50%;
    }

    .majorText {
      flex: 1;

      .majorNum {
        padding: 0 5px;
        color: red;
      }
    }
  }
}

.el-dialog__header {
  border-bottom: 1px solid #eee !important;
}

.el-dialog__body {
  padding: 20px !important;
}

.el-dialog .el-table .cell {
  white-space: nowrap !important;
}
</style>
